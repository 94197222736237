<template>
  <div class="content dis-flex4">
    <div class="content_box dis-flex2">
      <Goodsindex></Goodsindex>
    </div>
  </div>
</template>

<script>
import Goodsindex from '@/components/Goodsindex'
export default {
  name:'Goodslist',
  components:{Goodsindex}
}
</script>

<style lang="scss" scoped>
  .content{
    .content_box{
      width:1200px;
    }
  }
</style>