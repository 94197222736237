<template>
  <div class="content">
    <img class="goods_img" src="" alt="">
    <div class="goods_info">
      <div class="goods_name"></div>
      <div class="goods_spec"></div>
      <div class="dis-flex2">
        <div>
          <div class="goods_price"></div>
          <div class="goods_money"></div>
        </div>
        <div class="goods_num"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content{
  width:220px;
 .goods_img{
    height:220px;
    border-radius: 16px 16px 0 0;
  }
  .goods_info{
    padding:8px;
    border:1px solid #E5E5E5;
    border-radius: 0 0 16px 16px;
  }
}
 
</style>