<template>
  <div id="nav" class="pos-r">
     <!-- <Navigation :tapInfos="tapInfos" /> -->
  </div>
  <Goodslist></Goodslist>
  <!-- <Search></Search>
  <Footers></Footers> -->
  <!-- <Bottom></Bottom> -->
</template>

<script>
// @ is an alias to /src
// import Navigation from '@/components/Navigation'
import Goodslist from '@/components/Goodslist'
// import Search from '@/components/Search'
// import Footers from '@/components/Footers'
// import Bottom from '@/components/Bottom'

export default {
  name: 'Home',
  components: {
    // Navigation,
    Goodslist,
    // Search,
    // Footers,
    // Bottom,
    
  },
}
</script>
<style lang="scss">
#content{
  padding-top:80px;
  text-align: center;
  .bg_biaoge{
    padding:60px 0;
  }
}

</style>
